import { defineMessages, FormattedMessage } from 'react-intl';

import Faq from '@components/Faq';
import type { TimelineStatuses } from '@utils/order';
import { type TrackingOptionProps } from '@utils/types/order';

defineMessages({
    return_label: { id: 'orders.order.detail.tracking.decription.faq.return_label' },
});

const trackingOptions: Record<TimelineStatuses, (props: TrackingOptionProps) => React.ReactNode> = {
    validated: () => {
        return (
            <div className="flex flex-col">
                <FormattedMessage id="orderReturn.return.detail.tracking.validated" />
                <Faq returns={true} />
            </div>
        );
    },
    in_transit: () => {
        return (
            <div className="flex flex-col">
                <FormattedMessage id="orderReturn.return.detail.tracking.in_transit" />
                <Faq faqLinks={['exchange', 'refund_delay', 'defect']} returns={true} />
            </div>
        );
    },
};

export default trackingOptions;
