import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import type { GlobalECountry, Popin } from '@sezane/front-components';
import { PopinBanner, PopinClassic } from '@sezane/front-components';
import { useQuery } from '@tanstack/react-query';

import { useAppContext } from '@context/AppContext';
import { useCountry } from '@context/CountryContext';
import type { Routes } from '@hooks/useUrl';
import { useUrl } from '@hooks/useUrl';
import api from '@utils/api';

enum PopinAccountPages {
    account_index_new = 'account_index_new',
    account_wishlist = 'account_wishlist',
    account_ecard_balance = 'account_ecard_balance',
    account_return_step1 = 'account_return_step1',
}

const POPIN_ACCOUNT_PAGES_VALUES: Record<keyof typeof PopinAccountPages, keyof Routes> = {
    account_index_new: 'orders',
    account_wishlist: 'wishlists',
    account_ecard_balance: 'credits',
    account_return_step1: 'returnCreate',
};

export const checkPage = (popinPages: Array<keyof typeof PopinAccountPages> = [], routes: Routes, pathname: string) => {
    return Object.entries(POPIN_ACCOUNT_PAGES_VALUES).some(([page, route]) => {
        return pathname === routes[route] && popinPages.includes(page as keyof typeof PopinAccountPages);
    });
};

export const checkCountry = (popinCountries: string[] = [], country: GlobalECountry) => {
    if (popinCountries.length === 0) {
        return true;
    } else if (country.countryISO) {
        return popinCountries.includes(country.countryISO);
    }
    return false;
};

const Popins = () => {
    const intl = useIntl();
    const { brandCode: brand, site } = useAppContext();
    const { pathname } = useLocation();
    const { routes } = useUrl();
    const { country } = useCountry();

    const { data } = useQuery(['popins'], () =>
        api.getPopins({
            site: site.code!,
            brand,
            locale: intl.locale,
        })
    );

    const popins = data?.data || [];

    return (
        <>
            {popins.map(popin => {
                if (
                    !checkPage(popin.pages as Array<keyof typeof PopinAccountPages>, routes, pathname) ||
                    !checkCountry(popin.countries, country)
                ) {
                    return null;
                }

                switch (popin.format) {
                    case 'banner':
                        return <PopinBanner key={popin.id} popin={popin as Popin} />;
                    case 'popin':
                        return <PopinClassic key={popin.id} popin={popin as Popin} />;
                    default:
                        return null;
                }
            })}
        </>
    );
};

export default Popins;
