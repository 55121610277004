import { Fragment, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import { Radio, RadioGroup } from '@sezane/front-components';
import { useQuery } from '@tanstack/react-query';

import Loader from '@components/Loader/Loader';
import { useAppContext } from '@context/AppContext';
import { useUrl } from '@hooks/useUrl';
import apiOms from '@utils/apiOms';
import { DEFAULT_IMG_PLACEHOLDER } from '@utils/common';
import Step from './Step';
import { type FormValues, getCreateReturnSteps } from './utils';
import type { OMS } from 'types';

interface OrderRadioProps {
    order: OMS.OrderRead;
    selected?: boolean;
}

const MAX_DISPLAYED_ITEMS = 3;

const OrderRadio = ({ order, selected }: OrderRadioProps) => {
    const { routes } = useUrl();

    const mainProduct = order.products ? order.products[0] : undefined;

    return (
        <Radio
            className={clsx(
                'gap-6 border px-6 py-4 font-heading uppercase transition-colors',
                selected ? 'border-black' : 'border-gray-200'
            )}
            value={order.number || ''}
        >
            <div className="flex items-center">
                <div className="mr-4 shrink-0">
                    <img
                        className="h-[42px]"
                        alt={mainProduct?.label}
                        src={mainProduct?.imageUrl || DEFAULT_IMG_PLACEHOLDER}
                    />
                </div>
                <div className="flex flex-col justify-between">
                    {order.products && (
                        <div className="mb-1 text-sm font-medium text-black">
                            {order.products
                                .slice(0, MAX_DISPLAYED_ITEMS)
                                .map(({ ean, label, quantity }, index, products) => (
                                    <Fragment key={ean}>
                                        <span>
                                            {label}
                                            {!!quantity && quantity > 1 && (
                                                <span className="text-gray-600">
                                                    {' ('}
                                                    {quantity}
                                                    {')'}
                                                </span>
                                            )}
                                        </span>
                                        {index < products.length - 1 && ', '}
                                    </Fragment>
                                ))}
                            {order.products.length > MAX_DISPLAYED_ITEMS && (
                                <span className="text-gray-light">
                                    {' '}
                                    <FormattedMessage
                                        id="return.step_order.order.additional_products"
                                        values={{ nb: order.products.length - 1 }}
                                    />
                                </span>
                            )}
                        </div>
                    )}
                    <div className="text-sm text-gray-medium">
                        <Link
                            className="underline"
                            to={`${routes.orders}/detail/${order.number}`}
                            target="_blank"
                            onClick={event => event.stopPropagation()}
                        >
                            <FormattedMessage
                                id="return.step_order.order.reference"
                                values={{ number: order.number }}
                            />
                        </Link>
                        {' - '}
                        {order.totalDisplayPrice}
                    </div>
                </div>
            </div>
        </Radio>
    );
};

interface StepOrderProps {
    onChange: () => void;
    onInitOrderFromState: () => void;
}

const StepOrder = ({ onChange, onInitOrderFromState }: StepOrderProps) => {
    const { brandCode } = useAppContext();
    const { state } = useLocation();

    const { setValue } = useFormContext<FormValues>();

    const [orderReference, setOrderReference] = useState<string | undefined>(state?.orderId);

    const { data: ordersResponse, isFetching: isLoadingOrders } = useQuery(['getReturnableOrders'], () =>
        apiOms.omsAuthenticated.getReturnableOrders({
            // @ts-expect-error
            query: { brandCode },
        })
    );
    const orders = ordersResponse?.data;

    useEffect(() => {
        if (orders && state?.orderId) {
            const order = orders?.find(({ number }) => state.orderId === number);
            setValue('order', order);
            onInitOrderFromState();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orders]);

    useEffect(() => {
        const order = orders?.find(({ number }) => orderReference === number);
        setValue('order', order);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderReference]);

    return (
        <Step
            id={getCreateReturnSteps()[0]}
            title={<FormattedMessage id="return.step_order.title" />}
            description={<FormattedMessage id="return.step_order.description" />}
            withoutInitialScroll
        >
            {isLoadingOrders ? (
                <Loader className="mt-10" />
            ) : orders && orders.length > 0 ? (
                <RadioGroup
                    value={orderReference}
                    onChange={value => {
                        setOrderReference(value);
                        onChange();
                    }}
                >
                    {orders.map(order => (
                        <OrderRadio key={order.number} order={order} selected={order.number === orderReference} />
                    ))}
                </RadioGroup>
            ) : (
                <p className="mt-10">
                    <FormattedMessage id="return.step_order.no_orders" />
                </p>
            )}
        </Step>
    );
};

export default StepOrder;
